$screen-xs-min: 540px;
$screen-sm-min: 767px;
$screen-md-min: 991px;
$screen-lg-min: 1199px;

/** < 1199px **/
@media only screen and (max-width : $screen-lg-min) {
  .logo {
    img {
      max-width: 190px;
    }
  }

  .page-node-81 .main-container {
    max-width: 70%;
  }

  #navbar {
    .primary-nav {
      ul {
        li {
          a {
            padding: 18px 12px;
          }
          &:after {
            top: 22px;
          }
        }
      }
    }
    .navbar-toggle {
      border-color: transparent;
      color: #FFF;
      margin-right: 0;
      margin-top: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }

  .field-multiple-table {
    .form-wrapper {
      width: 28%;
      padding-left: 1%;
      padding-right: 1%;
      float: left;
      margin-bottom: 0;
      .form-group {
        margin-bottom: 0;
      }
      &.field-name-field-fecha-de-entrega {
        width: 34.4%;
      }
    }
  }
  .periodo-cosecha {
    .meses {
      .mes {
        p{
          min-width: 57px;
        }
      }

    }
  }
  .content-bottom {
    h2 {
      font-size: 40px;
      span {
        &:before, &:after {
          top: 26px;
        }
      }
    }
  }
  .header-bottom-image {
    max-height: 120px;
  }
  .node-type-galeria-de-imagenes {
    $width: 212px;
    .field-items {
      .field-item {
        width: $width;
      }
    }
    .field-videos {
      a {
        width: $width;
      }
    }
  }
  .view-per-odos-de-cosecha {
    .group-left{
      width: 20%;
    }
    .group-right{
      width: 80%;
    }
  }

}

/** < 991px **/
@media only screen and (max-width : $screen-md-min) {
  .logo {
    img {
      max-width: 160px;

    }
  }

  .flexslider .slides img {
    width: auto;
    max-width: none;
  }

  .navbar-wrapper {
    order: 3;
    flex: 1 0 100%;
  }

  #navbar {
    .primary-nav {
      .navbar-nav {
        display: flex;
        flex-direction: column;
        li {
          a {
            padding: 16px 0;
          }
          &:after {
            top: 20px;
          }
        }
      }
    }
  }
  .region-variedades-first {
    margin-bottom: 0;
  }
  .block--variedades {
    margin-top: 15px;
  }
  .list--especies {
    .view-content ul {
      li {
        width: 100%;
        &.views-row-odd {
          border-right-width: 0;
        }
        a {

        }
      }
    }
  }
  .btn-volver {
    font-size: 14px;
    .fa.pull-right {
      margin-top: 3px;
    }
  }
  .node-type-variedad {
    .group-stock {
      .btn-primary{
        padding-left: 5px;
        padding-right: 5px;
        letter-spacing: -0.05em;
      }
    }
  }
  .content-bottom {
    h2 {
      font-size: 32px;
      span {
        &:before, &:after {
          top: 21px;
        }
      }
    }
  }
  .header-bottom-image {
    max-height: 100px;
  }

  .node-type-galeria-de-imagenes {
    $width: 157px;
    .field-items {
      .field-item {
        width: $width;
      }
    }
    .field-videos {
      a {
        width: $width;
      }
    }
  }
  .table-responsive {
    border: none;
  }
  .field-multiple-table {
    .form-wrapper {
      width: 21%;
      &.field-name-field-fecha-de-entrega {
        width: 45%;
      }
    }
  }
  .page-eform-submit-solicitar-plantas-nuevas {
    .field-multiple-table {
      .form-wrapper {
        width: 33.3%;
        margin-bottom: 15px;
      }
      .field-name-field-fecha-de-entrega {
        width: 100%;
        margin-bottom: 0;
      }
      .btn-danger.form-submit {
        float: right;
        margin-top: 0;
      }
    }
  }
  .view-per-odos-de-cosecha {
    .group-left{
      width: 25%;
    }
    .group-right{
      width: 75%;
    }
  }
}

/** < 767px **/
@media only screen and (max-width : $screen-sm-min) {
  #navbar {
    .primary-nav {
      max-height: 100%;
      height: 100%;
      ul {
        width: 100%;
        padding: 10px 0;
        li {
          a {
            display: block;
            width: 100%;
            padding: 10px;
            font-size: 15px;
            &.active, &:hover, &:focus {
              color: #FFF;
              background-color: $brand-main-color;
            }
          }
          &:after {
            content:none;
            display: none;
          }
          ul.dropdown-menu {
            width: 100%;
            min-width: 100%;
            li{
              a{
                text-align: center;
                padding: 6px;
              }
            }
          }
        }
      }
    }
  }

  .page-node-81 .main-container {
    max-width: 80%;
  }

  h1.page-header {
    font-size: 27px;
  }
  .subtitle {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  .logo {
    img {
      max-width: 140px;
    }
  }
  .main-container {
    margin-top: 15px;
  }
  .node-type-variedad {
    .group-right {
      img{
        margin: 20px auto;
      }
    }
  }
  .periodo-cosecha {
    .meses {
      overflow-x: scroll;
      overflow-y: hidden;
      width: 100%;
      display: block;
      .mes {
        p {
          min-width: 61px;
        }
      }
    }
  }
  .view-per-odos-de-cosecha {
    .view-content {
      overflow-x: scroll;
      .periodo-cosecha {
        .meses {
          overflow-x: hidden;
          overflow-y: hidden;
          display: table;
        }
      }
    }
  }
  .content-bottom {
    h2 {
      font-size: 26px;
      span {
        &:before, &:after {
          top: 16px;
        }
      }
    }
    h3 {
      font-size: 22px;
    }
    .region-content-bottom {
      padding-bottom: 0;
    }
  }
  .header-bottom-image {
    max-height: 75px;
  }

  .node-type-galeria-de-imagenes {
    $width: 49%;
    $margin: 0.5%;
    .field-items {
      .field-item {
        width: $width;
        margin-left: $margin;
        margin-right: $margin+$margin;
        margin-bottom: 15px;
      }
      .field-item:nth-child(2n) {
        margin-left: $margin+$margin;
        margin-right: 0;
      }
    }
    .field-videos {
      a {
        width: $width;
        margin-left: $margin;
        margin-right: $margin+$margin;
        margin-bottom: 15px;
        &:nth-child(1n) {
          margin-left: 0;
          margin-right: $margin+$margin;
        }
        &:nth-child(2n) {
          margin-left: $margin+$margin;
          margin-right: 0;
        }
      }
    }
  }
  .triptych {
    .as-h3 {
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .descripcion {
      margin-bottom: 40px;
    }
  }
  .field-multiple-table {
    .form-wrapper {
      width: 100%;
      margin-bottom: 15px;
      &.field-name-field-fecha-de-entrega {
        width: 100%;
      }
    }
    .btn-danger.form-submit {
      float: right;
      margin-top: 0;
    }
  }
  .page-eform-submit-solicitar-plantas-nuevas {
    .field-multiple-table {
      .form-wrapper {
        font-size: 14px;
      }

      .btn-danger.form-submit {
        margin-top: 10px;
      }
    }
  }
  .view-per-odos-de-cosecha {
    .group-left{
      width: 30%;
    }
    .group-right{
      width: 70%;
    }
  }

}


/* < 540px */
@media only screen and (max-width : $screen-xs-min) {
  .view-per-odos-de-cosecha {
    .views-row {
      .group-left{
        width: 40%;
        font-size: 13px;
      }
      .group-right{
        width: 60%;
        mes {
          p {
            font-size: 13px;
          }
        }
      }
      h2 {
        font-size: 13px;
      }
    }
  }

  .page-node-81 .main-container {
    max-width: 100%;
  }

  .node-type-galeria-de-imagenes {
    .field-items {
      .field-item {
        margin-bottom: 8px;
      }
    }
  }


}

@media only screen and (max-width: 480px) {
  .logo img {
    max-width: 120px;
  }
}
