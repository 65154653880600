@import "css3-mixins.scss";

$text-body-color: #333333;
$text-light: #6c6d70;

$brand-main-color:         #f9a61a;
$brand-main-color-dark:    #f4821f;
$brand-main-color-darkest: #e87818;
$brand-main-color-lightest:  #fbd900;
$brand-main-color-ultra-light: #faf100;

$naranja: #E87817;
$naranjaDark:#f36e20;
$verde: #009140;
$verdeLight: #66A833;
$verdeDark: #547D2B;
$bgPrimary: #fbf8f3;
$bgFooter: #383330;
$linkSecondary: #615c5c;
$font-size:16px;

$orange-oranges: #f09947;
$orange-mandarins: #e87817;
$yellow-lemon: #e1b33d;
$yellow-grape: #facc05;
$pink-grape: #e66666;

$products-color:#f4821f;
$gardens-color: #096738;
$gallery-color: #fabf11;
$contact-color: #2e95d9;

$sizeH1: 32px;

$headings-font-family: "Roboto Condensed", "Helvetica", sans-serif;
$font-family-base: "Roboto", "Helvetica", sans-serif;

body {
  background-color: #FFF;
  color:$text-body-color;
  font-size:$font-size;
  font-family: $font-family-base;
}

p {
  line-height: 1.7;
  margin-bottom: 18px;
}

h1.page-header {
  color: $brand-main-color;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0;
  border:none;
  font-size: 31px;
  margin-bottom: 5px;
  padding-bottom: 0;
}

.region-header img {
  max-width: 206px;
}

h3 {
  color: $brand-main-color;
}

.subtitle {
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 300;
  color: #333333;
  font-size: 3.8rem;
  margin-bottom: 0;
  border-bottom: 2px solid #f36e20;
  display: block;
  padding-bottom: 1rem;
  margin-bottom: 3rem;

  @media(min-width: 992px) {
    max-width: 70%;
  }
}

label {
  color: $text-body-color;
}
.pswp__button--share {
  display: none;
}
/*.webform-component-textarea .grippie {
display: none;
}*/
/*.btn-yemas {
margin-top: -43px;
color: $naranjaDark;
font-size: 20px;
&:hover, &:active, &:focus {
text-decoration: none;
color: darken($naranjaDark, 20%);
}
img {
margin-top: -7px;
}
}*/
.form-control {
  background-color:$bgPrimary;
  color: $text-body-color;
  &:focus {
    border-color: darken($bgPrimary, 30%);
    box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px darken($bgPrimary, 40%);
  }
}

.main-container {
  margin-bottom: 2.25em;
}

/*.webform-container-inline {
label {
display: block;
}
}

.webform-component-date {
.webform-container-inline {
overflow: auto;
float: left;
width: 100%;
}
.form-type-select {
width: 100px;
float: left;
margin-right: 15px;
}
}*/

.navbar {
  padding: 1rem 0;

  & .logo {
    text-align: left;

    & img {
      max-width: 120px;
      margin: 0 auto;
      text-align: center;
      padding: 0;

      @media (min-width: 992px) {
        max-width: 170px;
      }
    }
  }
}


.navbar-toggle-container {
  order: 2;
  margin-left: auto;

  &::before {
    /*content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 1px;*/
  }
  & > .navbar-toggle {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0!important;

    & .fa {
      font-size: 2rem;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

#navbar {
  background-color: $brand-main-color;
  border-bottom: 5px solid $brand-main-color-darkest;
  position:static;


  .navbar-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;

    @media (min-width: 992px) {
      margin: 0 -15px;
    }
  }

  .primary-nav {
    max-height: none;
    background-color: rgba(255,255,255,0);
    border: 0;
    box-shadow: none;

    &.collapse {
      display: none!important;

      @media (min-width: 992px) {
        display: block!important;
        height: auto!important;
        padding-bottom: 0;
        overflow: visible!important;
      }

      &.in {
        display: block!important;
      }
    }

    &.affix {
      top: 0;
      left: 0;
      width: 100%;
      z-index: 200;
    }
    .navbar-nav {
      padding: 0;
      margin: 0;

      @media (min-width: 992px) {
        margin: 0 -1.5rem;
      }

      li {
        a {
          background-color:transparent;
          color: #FFF;
          font-size: 18px;
          font-weight: 300;
          position:relative;
          text-transform: initial;
          padding: 1.5rem;
          &.active, &:hover {
            color: #ffffff;
          }

          &.active {
            background-color: rgba(255,255,255,0);
          }
        }
        &::after {
          display: none;
        }
        @media (min-width: 992px) {
          &:not(:last-child)::before {
            content: "";
            position: absolute;
            right: -1px;
            border-right: 1px solid #FFF;
            width: 1px;
            height: 13px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }


  & .region-header-facebook {
    margin: initial;

    & img {
      max-width: 150px;
    }
  }
}

#main-nav {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 3rem;

  @media (min-width: 992px) {
    align-items: flex-end;
    text-align: right;
    margin-top: 0;
  }

  & > .region-navigation {
    order: -1;
  }
}

#navbar {
  .language-switcher-locale-url {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0 -5px!important;

    & > li {
      padding: 0 5px;

      &::after {
        display: none;
      }

      & > a {
        padding: 0!important;
        font-size: 12px!important;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &.active {
          color: $brand-main-color-ultra-light!important;
          background-color: rgba(255,255,255,0)!important;
        }
      }
    }
  }
}

.front #footer {
  margin-top: 0;
}
#footer {
  background-color: $brand-main-color;
  color: #fff;
  padding:20px 0 80px;
  position: relative;

  @media (min-width: 992px) {
    padding-bottom: 20px;
  }

  .region-footer-one {
    img {
      margin-top: 10px;
      max-width: 130px;
    }
  }
  .footer-bottom {
    padding: 0;
    margin: 0;

    & .back-to-top-block {
      position: fixed;
      right: 1em;
      bottom: 1em;
      & a {
        font-size: 3.375em;
        color: rgba(205,71,33,.7);
        display: block;
        line-height: 1;
      }
    }

    & .block-wp-floated {
      position: fixed;
      left: 1em;
      bottom: 1.125em;

      & .wp-icon {
        width: 50px;
      }
    }

    & .footer-extra {
      overflow: hidden;
      width: 100%;

      & .job-link {
        font-weight: bold;

        & > i {
          font-size: 14px;
        }
      }
    }

    p {
      font-size: 12px;
      color: darken($bgPrimary, 25%);
      a {
        color: darken($bgPrimary, 20%);
      }
    }
  }
}

.header-bottom-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 140px;
}

.region-destacado {
  overflow: hidden;

  .flexslider {
    border: none;
    margin-bottom: 0;
  }

}

.slider-home {
  & .slider-content {
    position: relative;
    min-height: 350px;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    color: #fff;

    @media (min-width: 992px) {
      min-height: 500px;
      padding: 8rem 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(0,0,0,.5), transparent 100%);
    }

    & > .flexslider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      z-index: 0;
      border: 0;

      & > .slides {
        display: flex;
        flex: 1;

        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & h1 {
      text-transform: uppercase;
      font-size: 1.8rem;
      margin-top: 0;
      font-family: $headings-font-family;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1;

      & > span {
        font-size: 6rem;
        letter-spacing: 0;
        display: block;
        font-weight: bold;
        transform: translateX(-2px);
      }

      @media (min-width: 992px) {
        font-size: 2.3rem;
        & > span {
          font-size: 8rem;
          transform: translateX(-4px);
        }
      }
    }

    & .lead {
      line-height: 1.3;
      font-size: 1.8rem;

      @media (min-width: 992px) {
        font-size: 2rem;
      }
    }

    & > .container {
      position: relative;
      z-index: 1;
    }
  }
}

.front .content-bottom {
  margin-top: 0;
  text-align: center;
}
.content-bottom {
  margin-top: 15px;
  .not-front & {
    background-color:$bgPrimary;
    padding: 6rem 0;

    & .section-header {
      display: block;
    }
  }
  // h2 {
  //   font-size: 45px;
  //   margin-bottom: 0;
  //   color: $brand-main-color;
  //   text-transform: uppercase;
  //   font-weight: bold;
  //   position: relative;
  //   display: inline-block;
  //   padding: 0 30px;
  //   span {
  //     position: relative;
  //     z-index: 2;
  //     padding: 0 10px;
  //     display: inline-block;
  //     font-weight: 400;
  //     margin-bottom: 16px;
  //     margin-bottom: 1rem;
  //   }
  // }
  h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    color: $text-light;
  }

  .form-control {
    background-color:#FFF;
  }
}

// page produccion
.page-node-82 {
  & .content-bottom {
    padding-bottom: 0;

    & .lead {
      &:last-child {
        margin-bottom: 50px;
      }
    }
  }
}

.produccion-content-bottom {
  @media (min-width: 768px) {
    & > .row {
      display: flex;

      & > .col-md-5 {
        align-self: flex-end;
      }
    }
  }
}

.front .content-bottom {
  margin: 2.25em 0;
}

.triptych {
  padding: 40px 0 60px 0;
  /*background-color:$bgPrimary;*/
  a:hover, a:focus, a:active, a:focus:active {
    text-decoration: none;
  }
  .as-h3{
    display: block;
    font-size: 20px;
    text-align: center;
    color: $text-body-color;
    margin: 30px 10% 16px;
    padding-bottom: 5px;
    position: relative;
    box-shadow: 0 1px #96989A;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid $text-light;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      bottom: -20px;
      left: 0;
      right: 0;
      margin: auto;
    }
    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      width: 0;
      height: 0;
      border: 9px solid #f3f3f3;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      bottom: -18px;
      left: 0;
      right: 0;
      margin: auto;
    }
    .h3-inside {
      font-weight: bold !important;
    }
  }
  .descripcion {
    display: block;
    text-align: center;
    padding: 5px 0;
    margin:5px 5%;
    line-height: 1.4;
    font-size: 15px;
    color: $text-body-color;
  }
}

.btn-primary {
  $color: $naranja;
  background: $color;
  border: 0px solid darken($color, 10%);
  &:hover,
  &:active,
  &:hover:focus,
  &:active:focus {
    background: darken($color, 5%);
  }
  &:focus {
    background: darken($color, 8%);
  }
}

.list--especies {
  margin-bottom: 30px;
  width: 100%;
  .block-title {
    background-color:$verdeDark;
    color: #FFF;
    text-align: center;
    text-transform: none;
    padding: 8px 0;
    margin: 0;
    font-weight: bold;
  }
  .view-content ul {
    list-style: none;
    margin: 0;
    padding:0;
    background-color:$bgPrimary;
    overflow: auto;
    li {
      display: inline-block;
      width: 50%;
      float: left;
      border-bottom: 1px solid #FFF;
      &.views-row-odd {
        border-right: 1px solid #FFF;
        &.views-row-last {
          border-bottom:none;
        }
      }
      a {
        padding: 3px 11px;
        display: block;
        color: $linkSecondary;
        &:hover, &:active, &:focus, &.active {
          text-decoration: none;
          background-color:$verdeLight;
          color: #FFF;
          .stock {
            color: #FFF;
          }
        }
        .stock {
          float: right;
          color: $brand-main-color-dark;
          font-size: 13px;
          font-weight: bold;
          line-height: 1.8;
        }
      }
    }
  }
  .view-footer {
    margin-top: 2px;
    border-top:1px solid $naranja;
    a {
      color: #FFF;
      background-color:$naranja;
      float: right;
      padding: 2px 26px;
      font-size: 14px;
      border-radius:0 0 5px 5px;
      text-align: center;
      &:hover, &:active, &:focus {
        text-decoration: none;
      }
    }
  }
}
.region-variedades-first {
  margin-bottom: 25px;
  float: left;
  width: 100%;
}
.node-type-variedad {
  .list--especies .block-title {
    background-color:lighten($linkSecondary,15%);
  }
  .page-header {
    background-color:$verdeDark;
    color: #FFF;
    padding: 10px;
    font-size: 24px;
    margin-bottom: 15px;
    display: none;
  }
  .group-left {
    background-color:$bgPrimary;
    border-radius:6px;
    padding:10px 15px;
    h2 {
      color: $naranjaDark;
      margin:0;
      padding:5px 0 10px 0;
      font-size: 22px;
    }
    h3 {
      margin:10px 0 10px 0;
      color: #FFF;
      background-color:$verdeLight;
      padding:5px 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .group-right {
    img {
      border:1px solid $bgPrimary;
      border-radius:6px;
    }
  }
  .group-propiedades, .group-observaciones {
    .field {
      background-color:#FFF;
      margin-bottom: 1px;
      .field-label {
        text-transform: none;
        color: $text-body-color;
        width: 30%;
        border-right:1px solid $bgPrimary;
        padding:2px 10px;
      }
      .field-items {
        padding:2px 10px;
      }
      p {
        padding:5px 0;
        margin:0;
      }
    }
  }
  .group-stock {
    margin-top: 10px;
    overflow: auto;
    .field {
      width: 50%;
      float: left;
    }
    .field-name-field-stock {
      background-color:$verdeDark;
      color: #FFF;
      padding: 5px 10px;
      border-right:1px solid #FFF;
    }
    .btn-primary {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .group-footer {
    float: left;
    width: 100%;
  }
  .meses {
    margin-bottom: 20px;
  }
}

.node-type-galeria-de-imagenes {
  $width: 262px;
  $margin:30px;
  .region-content img {
    border-radius: 6px;
  }

  & .photoswipe-gallery {
    margin-top: 3rem;
  }

  .photoswipe-gallery,
  .media-gallery-videos .mfp-gallery-iframe  {
    overflow: hidden;
  }
  .field-items {
    overflow: auto;
    .field-item {
      width: $width;
      margin-left: $margin;
      margin-right: $margin;
      margin-bottom: $margin;
      margin-top: 0;
      float: left;
      &:nth-child(1n) {
        margin-left: 0;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      a {
        position: relative;
        display: block;
        &:after {
          content:"";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.5s all;

        }
        &:hover:after {
          background-color: rgba($brand-main-color-dark, 0.3);
          border-radius: 6px;
        }
      }
    }
  }
  .field-videos {
    a {
      width: $width;
      margin: $margin;
      margin-top: 0;
      float: left;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 50px;
        height: 50px;
        background-image: url('../images/play.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }

      &:nth-child(1n) {
        margin-left: 0;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

.periodo-cosecha {
  margin-top: 20px;
  .title {
    border-bottom:2px solid $naranja;
    padding: 4px 0;
    span {
      background-color:$naranja;
      color: #FFF;
      border-radius:5px 5px 0 0;
      padding: 4px 8px;
    }
  }
  .meses {
    float: left;
    width: 100%;
    clear: both;
    display: table;
    .mes {
      border-right:1px solid #FFF;
      display: table-cell;
      &.mes-12 {
        border-right:none;
      }
      p{
        background-color:$verdeDark;
        font-size: 14px;
        font-weight: bold;
        color: #FFF;
        padding:5px;
        margin-bottom: 1px;
        min-width:68px;
        text-align: center;
      }
    }
    .semana {
      background-color:$bgPrimary;
      width: 25%;
      height: 22px;
      float: left;
      border-right:1px solid #FFF;
      &.selected {
        background-color:$naranjaDark;
        border-right:1px solid $naranjaDark;
      }
      &.semana-4 {
        border-right:none;
      }
      .porcentaje-cosecha {
        position: relative;;
        float: left;
        color: #FFFFFF;
        background-color:$naranjaDark;
        padding: 0 5px;
      }
    }
  }

}
/* Limones */
body.page-node-26 .periodo-cosecha .meses .semana .porcentaje-cosecha,
body.page-node-27 .periodo-cosecha .meses .semana .porcentaje-cosecha {
  line-height: 1;
  padding: 3px 6px;
}
.view-per-odos-de-cosecha {
  margin-right: 0;
  .view-content {
    overflow-x: scroll;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .group-left{
    width: 16%;
    float: left;
  }
  .group-right{
    float: left;
    width: 84%
  }
  .views-row {
    .field-collection-container {
      margin:0;
      border:none;
    }
    .periodo-cosecha {
      margin:0;
    }
    h2 {
      margin: 0;
      padding:4px 0;
      font-size: 16px;
    }
    .mes {
      p {
        height: 0px;
        visibility: hidden;
        padding: 0px;
      }
      .semana {
        height: 24px;
      }
    }
    &.views-row-first {
      h2 {
        margin-top: 20px;
      }
      .mes {
        p {
          height: auto;
          visibility: visible;
          padding:0;
        }
      }
    }
  }
}

#block-webform-client-block-21 {
  margin-top: 15px;
}

.colorized-gmap-common {
  height: 200px;
  width: 100%;
  margin-bottom: 35px;
}

.tabs--productos {
  margin-bottom: 25px;
  margin-top: 15px;
  li {
    a {
      color: $brand-main-color;

    }
  }
}

.btn-volver {
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  .fa.pull-right {
    margin-top: 3px;
    font-size: 18px;
  }
}

/** forms, no está muy prolijo... me preocupan más las pirañas **/
.date-form-element-content-multiline {
  padding: 0;
  border: none;
  .form-item {
    margin-right: 10px;
    span.form-required {
      display: none;
    }
  }
}
.btn-danger.form-submit {
  float: left;
  margin-top: 27px;
}
.field-multiple-table {
  .field-multiple-drag {
    display: none;
  }
  .form-wrapper {
    width: 30%;
    padding-left: 1%;
    padding-right: 1%;
    float: left;
    margin-bottom: 0;
    .form-group {
      margin-bottom: 0;
    }
  }
}

.page-eform-submit-solicitar-plantas-nuevas {
  .field-multiple-table {
    .form-wrapper {
      width: 19%;
    }
    .field-name-field-fecha-de-entrega {
      width: 35%;
    }
  }
}

.page-periodos-de-cosecha {
  h1.page-header {
    color: #FFF;
    background-color: $naranja;
    font-size: 15px;
    text-align: center;
    padding: 7px 0;
    border-radius: 5px 5px 0 0 ;
    text-transform: none;
    letter-spacing: -0.02em;
  }
  .btn-volver {
    float: right;
  }
}
.tabledrag-toggle-weight-wrapper {
  display: none;
}

.btn-danger {
  color: $naranjaDark;
  border-color: transparent;
  background-color: transparent;
  &:hover,&:active,&:focus, &:hover:focus, &:active:focus, &:active:hover, &:active:hover, &[disabled]:active, &[disabled]:focus, &[disabled]:hover {
    background-color: transparent;
    border-color: transparent;
    color: $naranjaDark;
    box-shadow: none;
  }
}

.btn-info {
  color: #FFF;
  border-color: $verdeDark;
  background-color: $verdeDark;
  &:hover,&:active,&:focus, &:hover:focus, &:active:focus, &:active:hover, &:active:hover, &[disabled]:active, &[disabled]:focus, &[disabled]:hover {
    background-color: $verdeDark;
    border-color: $verdeDark;
    color: #FFF;
  }
}

#cboxContent {
  .btn-volver {
    display: none;
  }
}


/*Trabaja con Nosotros*/


.page-node-81 .main-container {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.page-node-81 .page-header {
  margin-bottom: 1em;
}

.page-node-81 .node-webform {
  padding: 2.25em;
  border: 1px dashed #A6BFDC;
}

.checkbox+.checkbox, .radio+.radio {
  margin-top: 10px;
}

.page-node-81 .node-webform .form-type-checkbox,
.page-node-81 .node-webform .form-type-radio {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.page-node-81 .node-webform .webform-component--telefono ~ .form-item,
.page-node-81 .node-webform .webform-component--telefono ~ div > .form-item {
  margin: 0;
  padding: 1.5em 0;
  border-bottom: 2px solid #eee;
}

.page-node-81 .node-webform .form-actions {
  margin: 1.5em 0;
}

.page-node-81 .node-webform .webform-component--estudios-completados {
  border-top: 2px solid #eee;
  margin-top: 1.5em!important;
}

#edit-submitted-curriculum-vitae-upload {
  width: 50%;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.page-node-81 .node-webform .description {
  margin-top: 10px;
  font-size: .875em;
}

.page-node-81 .node-webform .form-actions .form-submit {
  width: 100%;
  font-size: 16px;
  padding: 10px 0;
}

.page-node-22 .main-container aside {
  margin-bottom: 2.25em;
}

.node-page h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

@media screen and (min-width: 992px) {
  .img-floated-md {
    float: left;
    max-width: 60%;
    margin-right: 5%;
  }
}

.well-empty {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.abrir-texto-container {
  padding: 1em 0;
  background-color: #fff;
  position: relative;

  & > .leer-mas {
    display: block;

    &:active,
    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;
    }

    & > .leer-mas-container {
      display: block;
      margin: auto;
      width: 41px;
      height: 26px;
      background-color: #fff;
      z-index: 4;
      position: relative;
      line-height: 30px;

      & img {
        border-radius: 0;
        display: block;
        transform: translateY(7px);
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $text-light;
    width: 100%;
    height: 1px;
    margin: auto;
  }
}

.empresa-block-images {
  text-align: center;

  & img {
    display: inline-block;
    @media screen and (max-width: 767px) {
      margin-bottom: 1em;
    }
  }
}

.lead {
  font-size: 16px;
  line-height: 1.75;

  @media (min-width: 992px) {
    font-size: 18px;
  }
}

.lead-bold {
  font-weight: 600;
  color: $text-light;
}

.spaced-region {
  padding: 3.375em 0 0;
}

.produccion-legend {
  margin-top: 72px;
  font-size: 36px;
  padding-top: 24px;
  border-top: 8px solid $brand-main-color;
  @media screen and (min-width: 768px) {
    white-space: nowrap;
    font-size: 45px;
  }
}

@media screen and (max-width: 768px) {
  .img-prod {
    max-width: 360px;
    margin: 0 auto;
    width: 100%;
  }

  .img-mapa {
    max-width: 295px;
    width: 100%;
    margin: 0 auto;
  }
}

.produccion-legend-icons {
  display: inline-block;
  vertical-align: top;
  max-width: 15%;
  line-height: 1;
  white-space: nowrap;

  &  .produccion-legend-arrow-big {
    color: $naranjaDark;
    vertical-align: middle;
  }

  & .produccion-legend-arrow-small {
    font-size: .88888888em;
    color: $brand-main-color;
    margin-left: -.775em;
    vertical-align: middle;
  }
}

.produccion-legend-text {
  display: inline-block;
  vertical-align: top;
  max-width: 85%;

  & > p {
    color: #1e1917;
    line-height: 1;
  }
  &  .produccion-legend-text-bold {
    font-weight: 700;
    white-space: nowrap;
  }

  &  small {
    font-style: italic;
    font-size: 21px;
    font-size: .46666666666em;
  }

  &  .produccion-legend-text-light {
    font-size: 28px;
    font-size: .62222em;
    font-weight: 700;
  }
}

.produccion-table {
  overflow-x: auto;

  & > img {
    pointer-events: none;
    max-width: 800px;

    @media (min-width: 1024px) {
      max-width: 100%;
    }
  }

  & > .produccion-table-image-desktop {
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }

  & > .produccion-table-image-mobile {
    display: block;

    @media (min-width: 992px) {
      display: none;
    }
  }

  @media screen and (max-width: 1199px) {
    max-height: 500px;
    cursor: all-scroll;
  }

  & table {
    min-width: 1139px;
    margin-top: 1em;
  }

  & thead .row-header th {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #cf4226;
      top: -8px;
      left: 0;
    }
  }

  & .row-header th {
    background-color: #aba8a8;
    font-weight: bold;
    text-align: center;
    border-right: 2px solid #fff;
    font-size: 12px;
    width: 160px;
    padding: 1.3333333em 0;
  }

  & .row-header th:first-child > span {
    display: inline-block;
    text-transform: uppercase;
  }

  & .row-header [colspan="4"] {
    color: #fff;
    border-right: none;
    width: 80px;

    &:nth-child(n+2)::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #c2c2c2;
      top: -8px;
      left: 0;
    }

    &:nth-child(n+3)::after {
      content: "";
      position: absolute;
      height: 110%;
      width: 2px;
      background: white;
      top: -1px;
      left: 4px;
      transform: rotate(10deg);
    }
  }

  & .row-header [colspan="48"] {
    background-color: #c2c2c2;
  }

  & [class^="lang-"] {
    display: block;
  }

  & .lang-en {
    font-weight: 300;
    display: none;
  }

  & .row-content th {
    font-weight: 400;
    color: #fff;
    font-size: 13px;
    border: 1px solid #fff;
  }

  & tbody .orange-oranges {

    & th {
      background-color: $orange-oranges;
    }

    & .active {
      background-color: $orange-oranges;
    }
  }

  & tbody .orange-mandarins {

    & th {
      background-color: $orange-mandarins;
    }

    & .active {
      background-color: $orange-mandarins;
    }

  }

  & tbody .yellow-lemon {

    & th {
      background-color: $yellow-lemon;
    }

    & .active {
      background-color: $yellow-lemon;
    }
  }

  & tbody .yellow-grape {

    & th {
      background-color: $yellow-grape;
    }

    & .active {
      background-color: $yellow-grape;
    }
  }

  & tbody .pink-grape {

    & th {
      background-color: $pink-grape;
    }

    & .active {
      background-color: $pink-grape;
    }
  }

  & th,
  & td {
    padding: .667em .296em;
  }

  & tbody td {
    font-size: 13px;
  }

  & .row-content td {
    background-color: #f0eeee;
    border: .2rem solid #fff;
  }
}


.featured-card {
  text-align: center;

  & .featured-icon {
    margin: 0 auto 2rem;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 1.8rem;
    border: .5rem solid;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 50px;
    }
  }

  & .featured-title {
    font-size: 3rem;
    margin-top: 0;
    color: $text-light;
    font-weight: bold;
  }

  & a {
    color: #fff;
    background-color: $brand-main-color-dark;
    padding: 0 3rem;
    text-transform: uppercase;
    font-weight: 300;
    display: inline-block;
  }

  &-products {
    & .featured-icon {
      border-color: $products-color;
    }
  }

  &-gardens {
    & .featured-icon {
      border-color: $gardens-color;
    }
  }

  &-gallery {
    & .featured-icon {
      border-color: $gallery-color;
    }
  }

  &-contact {
    & .featured-icon {
      border-color: $contact-color;
    }
  }
}

#footer {
  h3 {
    color: #fff!important;
  }
}

.footer {
  & > .container {

    & > .row {
      display: flex;
      flex-wrap: wrap;

      & > .col-xs-12:last-child {
        align-self: flex-end;
      }
    }
  }
}

.footer-brand {
  & h3 {
    margin: 0;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.25;

    & > span {
      display: block;
      font-size: 2.4rem;
      letter-spacing: -.1rem;
    }
  }
}

.footer-contact-list {
  margin-bottom: 0;

  & li {
    font-weight: 300;

    & .img-responsive {
      display: inline-block;
      vertical-align: middle;
    }

    & > p {
      margin-bottom: 0;
      font-size: 1.4rem;
    }

    & strong {
      text-transform: uppercase;
      font-weight: bold;
      color: $brand-main-color-lightest;
    }
  }
}

.social-links {
  display: flex;
  justify-content: flex-end;
  margin: 0 -5px 0;

  & > li {
    padding: 0 5px;
  }
}

.section-header {
  border-bottom: 2px solid $naranjaDark;
  display: inline-block;
  padding-bottom: 1rem;
  margin-bottom: 3rem;

  @media (max-width: 992px) {
    display: block;
  }

  & > h2 {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    color: $naranjaDark;
    font-size: 2.2rem;

    @media (min-width: 992px) {
      font-size: 3.8rem;
    }

    & > span {
      font-size: 1.8rem;
      color: lighten($text-light, 10%);
      font-weight: 300;
    }
  }

  & > h3 {
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 300;
    color: $text-body-color;
    margin-bottom: 0;
    font-size: 2.2rem;

    @media (min-width: 992px) {
      font-size: 3.8rem;
    }
  }
}



// Empresa
.page-node-23,
.page-node-87 {

 & .header-bottom-image {
    min-height: 600px;
    background-image: url('../images/empresa.jpg');
    background-size: cover;
    background-position: center;
  }

  & .section-header {

    @media(min-width: 992px) {
      width: 70%;
    }
  }

  & .field-name-body {
    & h4 {
      font-weight: 300;
      font-size: 2.4rem;
      color: $brand-main-color-dark;
    }

    & p {
      font-size: 1.6rem;
      font-weight: 300;

      @media (min-width: 992px) {
        font-size: 1.8rem;
      }
    }
  }
}

// Contacto

.page-node-22,
.page-node-85 {
  & .section-header {
    @media(min-width: 992px) {
      width: 70%;
    }
  }

  & [class^="webform-"] {
    & .form-submit {
      width: 100%;
      display: block;
    }
  }
}

.triptych {
  & [class*="region-triptych"] {

    @media (max-width: 992px) {
      margin-bottom: 30px;
    }
  }
}

.site-name {
  a {
    color: #fff;
    font-family: $headings-font-family;
    font-size: 2.2rem;

    @media (min-width: 360px) {
      font-size: 2.4rem;
    }
  }
}